import { initialFilters } from '../../store/initialStores';
//import { dataLayerFiltersType, dataLayerSearchPropList, getDataLayerQuery, getDataLayerLabel } from 'metrics/filtersDatalayers'

const filtersReducer = (state = initialFilters, action) => {
	const modifyStore = action.filter === 'location' ? { coordenates: initialFilters.coordenates, polygonapplied: initialFilters.polygonapplied} : {};

	switch(action.type) {
	case 'SET_MULTIPLE_LOCATIONS_FILTER': {
		const {
			value: { applied_label, min, name, applied },
			filter,
			submit,
		} = action

		// Declaro las variables con los valores anteriores ya actualizados
		const newAppliedLabel = [...state[filter].applied_label, applied_label]
		const newMin = {...state[filter].min, [applied_label.idType]: [...state[filter].min[applied_label.idType], min[applied_label.idType]]}
		// Traigo los valores, para pasar al dataLayer
		//const { dataLayerLabel } = getDataLayerLabel({ min: newMin })

		//dataLayerFiltersType({ action: 'Filtro', label: dataLayerLabel });

		if(submit) {
			//const { dataLayerQuery } = getDataLayerQuery({ appliedLabel: newAppliedLabel })

			//dataLayerSearchPropList(dataLayerQuery)
		}

		// Seteo la nueva data, para despues guardarlo en el store
		const newData = { min: newMin, applied_label: newAppliedLabel, name, applied }

		return {
			...state,
			[filter]: {
				...newData
			},
			lastFilterChanged: [filter, ...state.lastFilterChanged ],
			filterSubmit: submit ? state.filterSubmit + 1 : state.filterSubmit,
			...modifyStore
		}
	} break;
	case 'REMOVE_MULTIPLE_LOCATIONS_FILTER': {
		const {
			value: {idType, id, name, applied},
			filter,
			submit,
		} = action

		// Filtro la data anterior, para borrar del store la pill que se elimino
		const removeAppliedLabel = state[filter].applied_label.filter(e => e.id !== id)
		const removeMin = state[filter].min[idType].filter(e => e !== id)

		// Seteo la nueva data, para despues guardarlo en el store
		const newData = { min: {...state[filter].min, [idType]: removeMin }, applied_label: removeAppliedLabel, name, applied }

		return {
			...state,
			[filter]: {
				...newData
			},
			lastFilterChanged: [filter, ...state.lastFilterChanged ],
			filterSubmit: submit ? state.filterSubmit + 1 : state.filterSubmit,
			...modifyStore
		}
	} break;
	case 'CLEAR_MULTIPLE_LOCATIONS_FILTER': {
		const {
			value: { name, applied },
			filter,
			submit,
		} = action

		const newData = {
			min: {
				city: [],
				province: [],
				subZone: [],
				valueZone: [],
				zone: []
			},
			name,
			applied,
			applied_label: []
		}

		return {
			...state,
			[filter]: {
				...newData
			},
			lastFilterChanged: [filter, ...state.lastFilterChanged ],
			filterSubmit: submit ? state.filterSubmit + 1 : state.filterSubmit,
			...modifyStore
		}
	} break;
	case 'SET_BASIC_FILTER': {

		return {
			...state,
			[action.filter]: {
				...state[action.filter],
				...action.value
			},
			lastFilterChanged: [action.filter, ...state.lastFilterChanged ],
			filterSubmit: action.submit ? state.filterSubmit + 1 : state.filterSubmit,
			...modifyStore
		}
	} break;
	case 'REMOVE_APPLIED_FILTERS':
		return {
			...initialFilters,
			sort: state.sort,
			filterSubmit: action.submit ? state.filterSubmit + 1 : state.filterSubmit
		}

	case 'CLEAR_MORE_FILTERS':
		let savedFilters = {};
		Object.values(action.filters).forEach(item => {
			savedFilters = {...savedFilters, [item.toLowerCase()]: state[item.toLowerCase()]}
		})
		return {
			...initialFilters,
			sort: state.sort,
			...savedFilters,
			filterSubmit: state.filterSubmit + 1
		}

	case 'UPDATE_APLLIEDFILTERS':
		return {
			...initialFilters,
			boundary: state.boundary, // todavia no verifique q es esto
			...action.value,
			page: '',
		}

	case 'SET_FILTER_SUBMIT': {
		const { applied_label } = state.location
		const priceApplied = state.price.applied_label

		if(applied_label?.length) {
			//const { dataLayerQuery } = getDataLayerQuery({ appliedLabel: applied_label })

			//dataLayerSearchPropList(dataLayerQuery)
		}

		if(priceApplied?.length) {
			//dataLayerFiltersType({ action: 'Filtro', label: 'price' })
		}

		return {
			...state,
			filterSubmit: state.filterSubmit + 1
		}
	} break;
	case 'SET_PAGE':
		return {
			...state,
			page: action.value,
			filterSubmit: state.filterSubmit + 1
		}

	case 'SET_FILTER_COORDENATES':
		return {
			...state,
			coordenates: action.value,
			filterSubmit: state.filterSubmit + 1,
			polygonapplied: initialFilters.polygonapplied,
			location: initialFilters.location
		}
	}

	return state;
}

export default filtersReducer