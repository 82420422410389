const listReducer = (state = {}, action) => {
	switch(action.type) {
	case 'UPDATE_LIST_RESPONSE':

		return {
			...state,
			...action.value,
			mapPostings: action.value.mapPostings,
			listPostings: action.value.listPostings,
			listPostingsThinContent: action.value.listPostingsThinContent,
		}
	case 'UPDATE_ALERT_BUTTON':
		return {
			...state,
			showSavedAlert: action.value
		}
	case 'SET_MAP_VIEW':
		return {
			...state,
			mapView: action.value
		}
	case 'SET_MODAL_LOGIN':
		return {
			...state,
			hasModalLogin: action.value
		}
	case 'ADD_POSTING_COMPARE':
		return {
			...state,
			listPostingsCompare: [...state.listPostingsCompare, action.value]
		}
	case 'REMOVE_POSTING_COMPARE':
		return {
			...state,
			listPostingsCompare: action.value
		}
	case 'SET_TOOLTIP_ACTIVE':
		return {
			...state,
			tooltipActive: action.value
		}
	}
	return state;
}

export default listReducer