import { initialSession } from "../../store/initialStores";

const sessionReducer = (state = initialSession, action) => {
	switch(action.type) {
	case 'SET_SESSION':
		return {
			...state,
			gotSession: action.value
		}
		break;
	case 'SET_USER_LOGGED':
		return {
			...state,
			isLogged: action.value,
			gotSession: true
		}
		break;
	case 'SET_USER_SOFTLOGGED':
		return {
			...state,
			isSoftLogged: action.value
		}
		break;
	case 'SET_USER_DATA':
		return {
			...state,
			userData: action.data,
			isLogged: action.isLogged,
			isSoftLogged: action.isSoftLogged,
			gotSession: true
		}
		break;
	case 'SET_USER_ID':
		return {
			...state,
			userData: {...state.userData, id: action.data }
		}
		break;
	case 'SET_USER_ACTIVITY':
		return {
			...state,
			userActivity: action.data
		}
		break;
	case 'SET_USER_CONTACTED':
		return {
			...state,
			userActivity: {
				...state.userActivity,
				contactados: state.userActivity.contactados?.concat(action.value) || [action.value]
			}
		}
		break;
	case 'SET_USER_FAVORITE':
		let newValue;
		if(action.action === 'REMOVE') {
			newValue = state.userActivity.favoritos.filter(e => e !== action.value)
		} else {
			newValue = state.userActivity.favoritos?.concat(action.value) || [action.value]
		}
		return {
			...state,
			userActivity: {
				...state.userActivity,
				favoritos: newValue
			}
		}
		break;
	case 'SET_POSTINGS_WHATSAPP':
		return {
			...state,
			whatsapp: action.data
		}
	}
	return state;
}

export default sessionReducer;