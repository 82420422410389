const initialSession = {
	gotSession: false,
	isLogged: false,
	isSoftLogged: false,
	userData: {
		id: '',
		name: '',
		email: '',
		lastname: '',
		username: '',
		phone: '',
		dni: '',
		isPublisher: '',
		isParticular: '',
		isSeeker: '',
		idCompany: ''
	},
	userActivity: {
		vistos: [],
		contactados: [],
		favoritos: []
	},
	whatsapp: false
}

const initialList = (data) => {

	return {
		filters: data.filters || [],
		appliedFilters: data.appliedFilters || [] 
	}
}

const initialFilters = {
	lastFilterChanged: [],
	keyword: {
		name: 'KEYWORD',
		applied: false,
		min: null,
		applied_label: null
	},
	sort: {
		name: 'SORT',
		applied: false,
		min: null,
		applied_label: null
	},
	location: {
		name: 'LOCATION',
		applied: false,
		min: {
			city: [],
			province: [],
			valueZone: [],
			zone: [],
			subZone: [],
		},
		applied_label: [],
	},
	address: {
		name: 'ADDRESS',
		applied: false,
		min: null,
		applied_label: '',
	},
	realestatetype: {
		name: 'REALESTATETYPE',
		applied: false,
		min: null,
		applied_label: '',
	},
	realestatesubtype: {
		name: 'REALESTATESUBTYPE',
		applied: false,
		min: null,
		applied_label: '',
	},
	price: {
		name: 'PRICE',
		applied: false,
		currency: null,
		min: null,
		max: null,
		applied_label: '',
		suggestions: [],
	},
	includeexpensesprice: {
		name: 'INCLUDEEXPENSESPRICE',
		applied: false,
		min: 'false',
		applied_label: '',
	},
	banks: {
		name: 'BANKS',
		applied: false,
		min: [],
		applied_label: [],
	},
	amenidades: {
		name: 'AMENIDADES',
		applied: false,
		min: [],
		applied_label: [],
	},
	loanmonthly: {
		applied: false,
		currency: 1,
		min: null,
		max: null,
		applied_label: ''
	},
	expenses: {
		name: 'EXPENSES',
		applied: false,
		currency: 1,
		min: null,
		max: null,
		applied_label: '',
	},
	totalarea: {
		name: 'TOTALAREA',
		applied: false,
		area: 1, // 1 Cubierta- Total,
		currency: 1, // 1 Metros2 - 2 Hectareas
		min: null,
		max: null,
		applied_label: '',
	},
	operationtype: {
		name: 'OPERATIONTYPE',
		applied: false,
		min: null,
		applied_label: '',
	},
	developmentstage: {
		name: 'DEVELOPMENTSTAGE',
		applied: false,
		min: [],
		applied_label: [],
	},
	auctions: {
		name: 'AUCTIONS',
		applied: false,
		min: null,
		applied_label: '',
	},
	antiquity: {
		name: 'ANTIQUITY',
		applied: false,
		min: null,
		applied_label: '',
	},
	services: {
		name: 'SERVICES',
		applied: false,
		min: [],
		applied_label: [],
	},
	outside: {
		name: 'OUTSIDE',
		applied: false,
		min: [],
		applied_label: [],
	},
	caracteristicasprop: {
		name: 'CARACTERISTICASPROP',
		applied: false,
		min: [],
		applied_label: [],
	},
	comodidades: {
		name: 'COMODIDADES',
		applied: false,
		min: [],
		applied_label: [],
	},
	disposicion: {
		name: 'DISPOSICION',
		applied: false,
		min: null,
		applied_label: '',
	},
	general: {
		name: 'GENERAL',
		applied: false,
		min: [],
		applied_label: [],
	},
	searchbykeyword: {
		name: 'SEARCHBYKEYWORD',
		applied: false,
		min: [],
		applied_label: [],
	},
	areaprivativa: {
		name: 'AREAPRIVATIVA',
		applied: false,
		min: [],
		applied_label: [],
	},
	areacomun: {
		name: 'AREACOMUN',
		applied: false,
		min: [],
		applied_label: [],
	},
	environments: {
		name: 'ENVIRONMENTS',
		applied: false,
		min: null,
		label: ''
	},
	excludepostingcontacted: {
		name: 'EXCLUDEPOSTINGCONTACTED',
		applied: false,
		min: [],
		applied_label: [],
	},
	bathrooms: {
		name: 'BATHROOMS',
		applied: false,
		min: null,
		label: ''
	},
	habitacionesexacto: {
		name: 'INCLUDEEXACTVALUEBEDROOMS',
		min: false,
	},
	bedrooms: {
		name: 'BEDROOMS',
		applied: false,
		min: 0,
		minLabel: '',
		max: 0,
		maxLabel: '',
		label: '',
		action: ''
	},
	garages: {
		name: 'GARAGES',
		applied: false,
		min: null,
		label: ''
	},
	multimedia: {
		name: 'MULTIMEDIA',
		applied: false,
		min: [],
		applied_label: [],
	},
	publicationdate: {
		name: 'PUBLICATIONDATE',
		applied: false,
		min: null,
		label: ''
	},
	publishertype: {
		name: 'PUBLISHERTYPE',
		applied: false,
		min: null,
		label: ''
	},
	roomtype: {
		name: 'ROOMTYPE',
		applied: false,
		min: [],
		applied_label: [],
	},
	ambientesexacto: {
		name: 'INCLUDEEXACTVALUEROOMS',
		min: false,
	},
	rooms: {
		name: 'ROOMS',
		applied: false,
		min: 0,
		minLabel: '',
		max: 0,
		maxLabel: '',
		label: '',
		action: ''
	},
	multiplerets: {
		name: 'MULTIPLERETS',
		applied: false,
		min: [],
		applied_label: [],
	},
	coordenates: {
		name: 'COORDENATES',
		applied: false,
		min: null,
		label: 'Busqueda personalizada',
		applied_label: ''
	},
	polygonapplied: {
		name: 'POLYGONAPPLIED',
		applied: false,
		min: false,
		applied_label: false,
	},
	currency: {
		name: 'CURRENCY',
		applied: null,
		min: null,
		applied_label: null,
	},
	filterSubmit: 0
};

export { initialList, initialFilters, initialSession };